import React from 'react'
import './Button.css'

const ButtonP = ({buttonText}) => {
  return (
    <button className='primary-btn'>
      {buttonText}
    </button>
  );
};

export default ButtonP