import React from 'react'
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import Timeline from '../Components/Timeline';
import './About.css'
import Skills from './Skills';
const About = () => {
  return (
    <>
      <div className='about-div' id='about'>
        <div className='about-card'>
          <div className='about-left'>
            <h4>About Me</h4>
            <p>
              I am a self-taught DevOps Engineer and Web Developer with a strong
              background in front-end development and computer engineering. My
              passion for technology led me to complete a diploma in computer
              engineering in 2021 and secure a position as a DevOps Engineer at
              Actionfi. My expertise in a wide range of technologies, including
              Python, React, CSS, Javascript, Shell Script, Linux, Servers,
              Ansible, Docker, CI/CD, Git, Github Actions, and others, is a
              testament to my drive and determination to excel in my field. I am
              always motivated to expand my knowledge and stay up-to-date with
              the latest advancements in technology. I am proud to bring my
              skills, expertise, and passion to any project, and I am eager to
              continue making a positive impact in the world of technology.
            </p>
          </div>

          <div className='about-right'>
            <div className='c-box a-box'>
              <Link to='/career'>Career</Link>
            </div>
            <div className='s-box a-box'>
              <Link to='/skills'>Skills</Link>
            </div>
            <div className='p-box a-box'>
              <Link to='/projects'>Projects</Link>
            </div>

            <div className='g-box a-box'>
              <Link to='/gallery'>Gallery</Link>
            </div>
          </div>
        </div>
      </div>
      <Skills />
      <Timeline />
      <Footer />
    </>
  );
}

export default About