import React from 'react'
import ProjectCard from '../Components/ProjectCard'
import './Projects.css'
import Data from '../Assets/Data/DataProjects.json'
import { Helmet } from 'react-helmet'
import Footer from '../Components/Footer'
const Projects = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <title>Arjun Madavoor</title>
        <meta name='description' content='React and other projects' />
        <meta name='keywords' content='react, Projects, website' />
      </Helmet>
      <div className='project-div'>
        <h2 className='page-title'>Projects</h2>
        <div className='projectcard-container'>
          {Data.map((d) => (
            <ProjectCard
              key={d.id}
              project={d.project}
              description={d.description}
              host={d.host}
              github={d.github}
              image={d.image}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Projects