import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar';
import Timeline from './Components/Timeline';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Contact from './Pages/Contact';
import LandingPage from './Pages/LandingPage';
import Projects from './Pages/Projects';
import Skills from './Pages/Skills';
import Post from './Pages/Post';
import Login from './Pages/Login';
import { useState } from 'react';
import Dashboard from './Pages/Dashboard';
import Protected from './Pages/Protected';
import Gallery from './Pages/Gallery';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div className='App'>
      <Navbar />
      <Routes>
        {/* <Route path='/' element={<Home />} /> */}
        <Route path='/' element={<LandingPage />} />
        <Route path='/about' element={<About />} />
        <Route path='/Blogs' element={<Blog />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Career' element={<Timeline />} />
        <Route path='/Skills' element={<Skills />} />
        <Route path='/Gallery' element={<Gallery />} />
        <Route path='/Projects' element={<Projects />} />
        <Route path='/Blogs/:blogId' element={<Post />} />
        <Route
          path='/Login'
          element={
            <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
          }
        />
        <Route
          path='/Dashboard'
          element={
            <Protected isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}>
              <Dashboard
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Protected>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
