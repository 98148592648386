import React from 'react'

const ButtonS = ({ buttonText }) => {
  return (
    <button className='secondary-btn'>
      {buttonText}
    </button>
  );
};

export default ButtonS