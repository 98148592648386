import React, { useEffect } from 'react'
import BlogCard from '../Components/BlogCard'
import Pagination from '../Components/Pagination';
import './Blog.css'
import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebase-config'
import { useState } from 'react';
import Loader from '../Components/Loader';
import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
const Blog = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  window.scrollTo(0, 0);


  useEffect(() => {
    setLoading(true)
    const postCollectionRef = collection(db, 'posts');
    const getPosts = async () => {
      const data = await getDocs(postCollectionRef);
      setPostList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false)
    };
    
    getPosts();
  }, [searchTerm]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = postList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  const handleFilter = () => {
    setPostList(
      postList.filter((obj) =>
        obj.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // perform search here using the searchTerm value
    handleFilter()
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Arjun's Blog</title>
        <meta
          name='description'
          content='A comprehensive guide to the latest IT technologies, including Docker, Ansible, CI/CD, JavaScript, shell script and React. Get the latest tips and tricks, how-to guides, and expert insights on how to effectively use these tools in the world of IT.'
        />
        <meta
          name='keywords'
          content='portfolio, Arjun madavoor, Docker, Ansible, CI/CD, JavaScript, React, IT technologies, tips, tricks, how-to guides, expert insights, IT field, blog, portfolio, CSS, CSS tricks'
        />
      </Helmet>
      <div className='blog-div'>
        <form className='search-form contact-form' onSubmit={handleSubmit}>
          <input
            className='feedback-input'
            type='text'
            placeholder='Search'
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <button type='submit'>Submit</button>
        </form>

        <div className='blog-div-2'>
          {loading ? (
            <Loader />
          ) : (
            currentPosts.map((d, i) => (
              <BlogCard
                id={d.id}
                key={i}
                title={d.title}
                subtitle={d.subtitle}
                intro={d.intro}
                content={d.content}
                date={d.date}
                author={d.author}
                color={d.color}
                tag={d.tag}
              />
            ))
          )}
        </div>
      </div>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={postList.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      <Footer />
    </>
  );
}

export default Blog