
import React from 'react'
import './Gallery.css'
import MyPic from '../Assets/images/me5.jpg'
const Gallery = () => {
  return (
    <div className="gallery-div">
      <div className="gallery-container">
        <div className="gallery-card">
          <img className='gallery-img' src={MyPic} alt="Arjun Madavoor" />
        </div>
      </div>
    </div>
  )
}

export default Gallery