import React from 'react'
import './Contact.css'
import Footer from '../Components/Footer';
import { useState } from 'react';
const Contact = () => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');


  const handleClick = (e) => {
    e.preventDefault();
    window.open(`mailto:arjunmdrcalicut@gmail.com?subject=${subject}&body=${name}: ${message}`);
    setName('')
    setSubject('');
    setMessage('');
  };
  return (
    <div className='contact-div' id='contact'>
      <div className='contact-container'>
        <h2 className='page-title'>Contact Me</h2>
        <form
          className='contact-form'
          method='post'
          encType='text/plain'
          onSubmit={handleClick}
        >
          <input
            name='name'
            type='text'
            className='feedback-input'
            placeholder='Name'
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            name='subject'
            type='text'
            className='feedback-input'
            placeholder='Subject'
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
          <textarea
            name='text'
            className='feedback-input'
            placeholder='Message'
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          ></textarea>
          <input type='submit' value='SUBMIT' />
        </form>
      </div>

      <Footer />
    </div>
  );
}

export default Contact