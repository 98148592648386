import React from 'react'
import {FaGithub, FaInstagram, FaLinkedin} from 'react-icons/fa'
import './Footer.css'
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className='footer-div'>
        <div className='footer-social'>
          <ul>
            <li>
              <a
                href='https://github.com/arjunmadavoor'
                target='_blank'
                rel='noreferrer'
              >
                <FaGithub />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/arjun__mdr/'
                target='_blank'
                rel='noreferrer'
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/in/arjun-m-9a7231194'
                target='_blank'
                rel='noreferrer'
              >
                <FaLinkedin />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <p className='copyright-text'>
            <span>
              {' '}
              Copyright &copy; {currentYear} All Rights Reserved by Arjun
              Madavoor
            </span>
            .
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer