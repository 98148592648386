import React from 'react';
import './ProjectCard.css'
import reactwebsite1 from '../Assets/images/react-website-1.png';
import youtubeapp from '../Assets/images/youtube-app.png';
import todoapp from '../Assets/images/todo-app.png';
import fylolanding from '../Assets/images/fylo-landing.png';
import portscan from '../Assets/images/port-scan.png';
import articlesummarizer from '../Assets/images/article-summarizer.png';


const ProjectCard = ({project, description, languages, host, github, image}) => {
  return (
    <div className='project-card'>
      <div
        className={
          image === 'temple-billing' ? 'project-img-exp' : 'project-img'
        }
      >
        <img
          src={
            image === 'react-website-1'
              ? reactwebsite1
              : image === 'youtube-app'
              ? youtubeapp
              : image === 'fylo-landing'
              ? fylolanding
              : image === 'todo-app'
              ? todoapp
              : image === 'port-scan'
              ? portscan
              : image === 'article-summarizer'
              ? articlesummarizer
              : ''
          }
          alt=''
        />
      </div>
      <div className='project-detail'>
        <span className='p-title'>{project}</span>
        <span className='p-detail'>{description}</span>
        <span className='p-button'>
          <a href={github} target='_blank' rel='noreferrer'>
            <button className='p-btn p-btn-primary'>Code</button>
          </a>
          <a href={host} target='_blank' rel='noreferrer'>
            <button className='p-btn p-btn-secondary'>Demo</button>
          </a>
        </span>
      </div>
    </div>
  );
}

export default ProjectCard