import React from 'react';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ isLoggedIn }) => {
  const [navbar, setNavbar] = useState(false);
  const handleClick = () => {
    setNavbar(!navbar);
  };
  return (
    <>
      <div className='nav-div'>
        <h1 className='nav-logo'>
          <Link to='/'>
            Arjun <br />
            Madavoor
          </Link>
        </h1>
        <span className='burger-menu' onClick={handleClick}>
          {navbar ? <FaTimes /> : <FaBars />}
        </span>
        <ul className={navbar ? 'nav-list active' : 'nav-list'}>
          <li className='nav-item' onClick={handleClick}>
            <Link to='/'>Home</Link>
          </li>
          <li className='nav-item' onClick={handleClick}>
            <Link to='/about'>About</Link>
          </li>
          <li className='nav-item' onClick={handleClick}>
            <Link to='/blogs'>Blog</Link>
          </li>
          <li className='nav-item' onClick={handleClick}>
            <Link to='/projects'>Projects</Link>
          </li>
          <li className='nav-item' onClick={handleClick}>
              <Link to='/contact'>Contact</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
