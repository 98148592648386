import React from 'react'
import './Timeline.css'
const Timeline = () => {
  return (
    <div className='timeline-div'>
      <h2 className='page-title'>My Journey</h2>
      <div className='timeline'>
        <div className='container left'>
          <div className='content'>
            <h2>2015</h2>
            <p>In 2015 I completed my SSLC with an A+ grade in all the subjects.
            </p>
          </div>
        </div>
        <div className='container right'>
          <div className='content'>
            <h2>2018</h2>
            <p>Completed my Plus two with a score of 86%</p>
          </div>
        </div>
        <div className='container left'>
          <div className='content'>
            <h2>2021</h2>
            <p>
            Completed my Diploma in Computer Engineering. 🎓
            </p>
          </div>
        </div>
        <div className='container right'>
          <div className='content'>
            <h2>2021</h2>
            <p>
            Started my first Job as a DevOps Engineer. 👨‍💻
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline