import React from 'react';
import Contact from './Contact';
import Home from './Home'

const LandingPage = () => {
  return (
    <div>
      <Home />
      <Contact />
      {/* <About />
      <Skills />
      <Projects />
      <Contact /> */}
    </div>
  );
}

export default LandingPage