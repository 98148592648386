import React, { useState } from 'react'
import { addDoc, collection } from 'firebase/firestore'
import { db, auth } from '../firebase-config'
import './CreatePost.css'
import { useNavigate } from 'react-router-dom';
const CreatePost = () => {
  let navigate = useNavigate();
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();

  const [content, setContent] = useState();
  const [intro, setIntro] = useState();

  const [date, setDate] = useState();
  const [author, setAuthor] = useState('');
  const [color, setColor] = useState();
  const [tag, seTag] = useState();
  const [keyword, setKeyword] = useState();

  const postCollectionRef = collection(db, "posts")

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addDoc(postCollectionRef, {
        title,
        subtitle,
        intro,
        content,
        date,
        author,
        uid: auth.currentUser.uid ,
        color,
        tag,
        keyword
      });
    } catch (error) {
      console.log(error);
    }
    navigate('/blogs')
  }
  return (
    <div>
      <form className='post-form' onSubmit={handleSubmit}>
        <input
          type='text'
          placeholder='Title'
          onChange={(e) => setTitle(e.target.value.replace(/\s+/g, '-'))}
        />
        <input
          type='text'
          placeholder='Sub Title'
          onChange={(e) => setSubtitle(e.target.value)}
        />
        <input
          type='text'
          placeholder='Introduction'
          onChange={(e) => setIntro(e.target.value)}
        />
        <textarea
          type='text'
          placeholder='Content'
          onChange={(e) => setContent(e.target.value)}
        />
        <input
          type='text'
          placeholder='Publish date: Day Month Year'
          onChange={(e) => setDate(e.target.value)}
        />
        <input
          type='text'
          placeholder='Author'
          onChange={(e) => setAuthor(e.target.value)}
        />
        <input
          type='text'
          placeholder='Color'
          onChange={(e) => setColor(e.target.value)}
        />
        <input
          type='text'
          placeholder='Tags'
          onChange={(e) => seTag(e.target.value)}
        />
        <input
          type='text'
          placeholder='Keywords - seperated by coma'
          onChange={(e) => setKeyword(e.target.value)}
        />
        <input className='submit-btn-blog' type='submit' />
      </form>
    </div>
  );
}

export default CreatePost

