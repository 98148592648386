import React from 'react'
import { Link } from 'react-router-dom'
import './BlogCard.css'
const BlogCard = ({title, subtitle, intro, content, author, date, color, id, tag}) => {
  const str = intro.substring(0, 200);
  var lastIndex = str.lastIndexOf(' ');
  const introduction = intro.substring(0, lastIndex);
  const myStyle = {
    backgroundColor: `${color}`
  }
  return (
    <Link to={`/blogs/${title}`} className='blog-card-link'>
      <div className='blog-card'>
        <div className='blog-img' style={myStyle}>
          <img src='' alt='' />
          <span className='p-date'>Published: {date}</span>
          <span className='p-author'>Author: {author}</span>
        </div>
        <div className='blog-detail'>
          <div className='b-tag'>
            {tag.split(',').map((tag, i) => (
              <span key={i} className='tag' style={myStyle}>
                {tag}
              </span>
            ))}
          </div>
          <span className='b-title'>{subtitle}</span>
          <span className='b-detail'>{introduction}....</span>

          <span className='b-button'>
            <Link to={`/blogs/${title}`}>
              <button className='b-btn b-btn-primary'>Read More</button>
            </Link>
          </span>
        </div>
      </div>
    </Link>
  );
}

export default BlogCard