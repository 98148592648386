import React from 'react'
import SkillCard from '../Components/SkillCard'
import Data from '../Assets/Data/DataSkills.json'
import './Skills.css'
import { Helmet } from 'react-helmet'
const Skills = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Arjun Madavoor</title>
        <meta name='description' content='Tools and technologies I wroked as a Developer' />
        <meta name='keywords' content='React,CSS,HTML,Docker,Django,Ansible,CI/CD,Github Actions' />
      </Helmet>

      <div className='skill-div'>
        <h2 className='page-title'>Technologies I worked</h2>
        <div className='skillcard-container'>
          {Data.map((d) => (
            <SkillCard
              key={d.id}
              percentage={d.percentage}
              title={d.title}
              bgcolor='red'
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills