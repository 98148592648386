import React from 'react'
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Protected = ({ isLoggedIn, children }) => {
  const cookie = Cookies.get('accessId')

  if (cookie === undefined) {
    return <Navigate to='/login' replace />
  }
  return children;
};

export default Protected;