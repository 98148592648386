import { signOut } from 'firebase/auth';
import React, { useState } from 'react'
import { auth } from '../firebase-config';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'
import CreatePost from '../Blog/CreatePost';

const Dashboard = ({ setIsLoggedIn, isLoggedIn }) => {
  const [action, setAction] = useState('');
  let navigate = useNavigate();

  
  const HandleLogout = async () => {
    try {
      await signOut(auth).then(setIsLoggedIn(false));
      localStorage.clear()
      Cookies.remove('accessId')
      !Cookies.get('accessId') && navigate('/login');
    } catch (error) {
      console.log(error.message)
    }
  };

  const showCreate = (e) => {
    setAction('create');
  }
  const showEdit = (e) => {
    setAction('edit');
  };
  return (
    <div className='dashboard-div'>
      <div className='dashnav'>
        <ul className='dashnav-list'>
          <li className='dashnav-item'>
            <button onClick={showCreate}>Create Post</button>
          </li>
          <li className='dashnav-item'>
            <button onClick={showEdit}>Edit Post</button>
          </li>
          <li className='dashnav-item'>
            <button>Web Analytics</button>
          </li>
          <li className='dashnav-item'>
            <button onClick={HandleLogout}>Log Out</button>
          </li>
        </ul>
      </div>

      <div className='dash-container'>
        <h4>Admin Dashboard</h4>
        <div>{action === 'create' ? <CreatePost /> : ''}</div>
      </div>
    </div>
  );
};

export default Dashboard