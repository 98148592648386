import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './Post.css'
// import Avatar from '../Assets/images/avatar.png'
import Me from '../Assets/images/avatar30x30.jpg';
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase-config'
import parse from 'html-react-parser';
import {Helmet} from "react-helmet";

const Post = () => {
  let { blogId } = useParams();
  
  const [filtered, setFiltered] = useState([{'author': '', 'color': '', 'content':'', 'date': '', 'id': '', 'tag': '', 'title': ''}]);
  window.scrollTo(0, 0);

    useEffect(() => {
      const postCollectionRef = collection(db, 'posts');
      const getPosts = async () => {
        const data = await getDocs(postCollectionRef);
        const res = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const filter = res.filter((item) => item.title === blogId);
        setFiltered(filter);
        // window.scrollTo(0, 0);
      };
      getPosts();
    },[blogId]);

  const myStyle = {
    backgroundColor: `${filtered[0].color}`
  };
  // const getHtmlFormat = () => {
  //   let htmldata = parse('<h1>single</h1>');
  //   setHtml(htmldata)
  // }
  // getHtmlFormat()
  let htmldata = parse(filtered[0].content);
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{filtered[0].title}</title>
        <meta name='description' content={filtered[0].intro} />
        <meta name='keywords' content={filtered[0].keyword} />
      </Helmet>
      <div className='post-div'>
        <div className='post-container'>
          <div className='post-header'>
            <h1>{filtered[0]?.title?.replace(/-/g, ' ')}</h1>
            <div className='sub-head'>
              <div className='sub-head-author-section'>
                <div className='avatar-div'>
                  <img className='me-avathar' alt='avatar' src={Me} />
                  {/* <img alt='avatar' src={Avatar} /> */}
                </div>
                <div className='auth-pub'>
                  <h4>{filtered[0].author}</h4>
                </div>
              </div>
              <div className='b-tag'>
                {filtered[0].tag.split(',').map((d, i) => (
                  <span key={i} className='tag' style={myStyle}>
                    {d}
                  </span>
                ))}
              </div>
            </div>
            <div className='post-img-div' style={myStyle}></div>
          </div>
          <div className='post-body'>
            <p>{htmldata}</p>
          </div>
          <div className='post-footer'></div>
        </div>
      </div>
    </>
  );
}

export default Post