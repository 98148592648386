import React, { useEffect } from 'react'
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase-config'
import './Login.css'
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
const Login = ({ setUser, setIsLoggedIn, isLoggedIn }) => {
  let navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState();
  const [loginPassword, setLoginPassword] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const cookie = Cookies.get('accessId');
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // user && Cookies.set('accessId', user.uid, { expires: 1 })
      user && localStorage.setItem('isLoggedIn', true);

    });

    return unsubscribe;
  }, [errorMessage]);
  
  const handleLogin = async (e) => {
    e.preventDefault()
    setErrorMessage("")
    try {
      const res = await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
      Cookies.set('accessId', res.user.uid, { expires: 1 });
      Cookies.get('accessId') ? navigate('/dashboard') : navigate('/');
      setErrorMessage('');
    } catch (error) {
      console.log(error.message);
      if (error.message.includes("invalid-email") ) {
        setErrorMessage("invalid email")
      } else if (error.message.includes("wrong-password")) {
        setErrorMessage("invalid password")
      } else if (error.message.includes('user-not-found')) {
        setErrorMessage('user not found');
      } else if (error.message.includes('internal-error')) {
        setErrorMessage('Please fill the fields');
      } else {
        console.log(error.message);
      }
    }
  };

  if (cookie) {
    return <Navigate to='/dashboard' />
  }
  return (
    <div className='login-div'>
      <form onSubmit={handleLogin}>
        <div className='login-container'>
          <h4>Login Here</h4>
          <p className='login-error'>{errorMessage}</p>
          <div>
            <div className='user-div'>
              <input
                type='text'
                placeholder='Username'
                onChange={(e) => setLoginEmail(e.target.value)}
                required
              />
            </div>

            <div className='pswd-div'>
              <input
                type='password'
                placeholder='Password'
                onChange={(e) => setLoginPassword(e.target.value)}
                required
              />
            </div>

            <button type='submit'>Log In</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login