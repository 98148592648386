import React from 'react'
import HTML from '../Assets/images/html.svg'
import JAVASCRIPT from '../Assets/images/js.svg';
import CSS from '../Assets/images/css.svg';
import BOOTSTRAP from '../Assets/images/bootstrap.svg';
import REACT from '../Assets/images/react.svg';
import PYTHON from '../Assets/images/python.svg';
import SELENIUM from '../Assets/images/selenium.svg';
import GIT from '../Assets/images/git.svg';
import GITHUB from '../Assets/images/github.svg';
import AZURE from '../Assets/images/azure.svg';
import LINUX from '../Assets/images/linux.png';
import DOCKER from '../Assets/images/docker.svg';
import ANSIBLE from '../Assets/images/ansible.svg';
import DJANGO from '../Assets/images/django.svg';





import './SkillCard.css'

const SkillCard = ({percentage, bgcolor, title}) => {
  // const Parentdiv = {
  //   backgroundColor: 'whitesmoke',
  // }

  // const Childdiv = {
  //   height: '100%',
  //   width: `${percentage}%`,
  //   backgroundColor: bgcolor,
  //   textAlign: 'right',
  //   // transform: 'translateY(100%)'
  // };
  return (
    <div>
      <div className='skill-card'>
        <img
          src={
            title === 'HTML'
              ? HTML
              : title === 'PYTHON'
              ? PYTHON
              : title === 'CSS'
              ? CSS
              : title === 'BOOTSTRAP'
              ? BOOTSTRAP
              : title === 'REACT'
              ? REACT
              : title === 'SELENIUM'
              ? SELENIUM
              : title === 'JAVASCRIPT'
              ? JAVASCRIPT
              : title === 'DOCKER'
              ? DOCKER
              : title === 'ANSIBLE'
              ? ANSIBLE
              : title === 'AZURE'
              ? AZURE
              : title === 'GITHUB'
              ? GITHUB
              : title === 'GIT'
              ? GIT
              : title === 'LINUX'
              ? LINUX
              : title === 'DJANGO'
              ? DJANGO
              : ''
          }
          alt=''
        />
        <span>{title}</span>
      </div>
    </div>
  );
}

export default SkillCard